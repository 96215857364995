import axios from 'axios';

export const api = axios.create({
  /*
  baseURL: "https://reserva.hubin.io/iomanager/api/flows/execute/route/expedition-tracking",
  headers: {
    'Content-Type': 'application/json',
    'x-hubin-access-token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJSZXNlcnZhIEUtY29tbWVyY2UiLCJ0ZW5hbnQiOiJyZXNlcnZhIiwidHlwZVRva2VuIjoiMiIsImV4cCI6MTAyNjM4NDgxODMwNjl9.kDRhLYK0V_LuuTRwQbEN8n3Hh6lFIRXwdrLJpnK1IPw'
  }
  */
  baseURL: 'https://tracking.joinar.com.br/tracking'
})