import React, { useState, useEffect, useCallback } from 'react';
import reserva from './images/Logo-Reserva.svg'
import oficina from './images/Logo-Oficina.svg'
import mini from './images/Logo-Mini.svg'
import './App.css';

import { api } from './services/api';

function App() {
  const [CPF, setCPF] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const { data } = await api.get(`/${CPF}`)
    setData(data);
    setIsLoading(false);
  }, [CPF])

  useEffect(() => {
    const urlCpf = window.location.pathname.replace(/[^0-9]/g, '');
    if (urlCpf.length === 11) {
      setCPF(urlCpf);
      handleSubmit();
    }
  }, [handleSubmit])

  console.log("teste", data)

  return (
    <div className="container">
      <div className="content">
        <header>
          <img src={reserva} alt="Reserva" />
          <img src={oficina} alt="Oficina" />
          <img src={mini} alt="Mini" />
        </header>
        { data.length > 0 && isLoading === false ? (
           <React.Fragment>
             { data.map((p, k) => (
                <div className="order" key={k}>
                  <span className="m-6">Pedido: { p.pedido || "-" }</span>
                  <span className="m-6">Status do pedido: { p.status }</span>
                  <span className="m-6">Nova data de entrega: { p.novadata }</span>
                  <span className="m-6">Valor: { p.valor }</span>
                  <div className="inline"></div>
                </div>
             ))}
             <a href="https://www.usereserva.com/" className="siteBtn">Ir para o Site</a>
           </React.Fragment>
        ) : (
          <React.Fragment>
            { isLoading ? (
              <h3 className="loading">Carregando...</h3>
            ) : (
             <main>
              <p className="title">
                Consulte o seu prazo de entrega
              </p>
              <form className="form" onSubmit={handleSubmit}>
                <input 
                  value={CPF}
                  placeholder="Digite aqui o seu CPF"
                  type="tel"
                  className="form-control"
                  onChange={(e) => setCPF(e.target.value)}
                />
                <button type="submit">
                  Enviar
                </button>                
              </form>              
              <span className="subtitle">insira números, sem pontos e traços</span>
              
           </main>
            ) }
          </React.Fragment>
        ) }
      </div>
    </div>
  );
}

export default App;
