import { init as initApm } from '@elastic/apm-rum'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

initApm({
  serviceName: 'react-expedition-tracking',
  serverUrl: 'https://apm.timereserva.com',
  secretToken: 'Bearer 45e620ab-dec6-486d-8dac-bcc04f296bc9',
  serviceVersion: '',
  environment: 'production',
  frameworkName: 'react',
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);